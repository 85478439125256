import { _getEnv, _isFeDev, _log } from '@shared/aux_helper_environment';
import { AsyncPipe, DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FuseModule } from '@fuse/fuse.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { ForbiddenComponent } from '@prisma/forbidden/forbidden.component';
import { DataAnauthorizedService, UnauthorizedComponent } from '@prisma/unauthorized/unauthorized.component';
import { AuthModule, OidcConfigService, OidcSecurityService } from 'angular-auth-oidc-client';
import { AppComponent } from 'app/app.component';
import { fuseConfig } from 'app/fuse-config';
import { LayoutModule } from 'app/layout/layout.module';
import { NgxsFormPluginModule } from 'core/form-plugin/public_api';
import { DisplayedColumnsService } from 'core/services/export-to-csv/displayed-columns-service';
import { ExportToCsvFileServices } from 'core/services/export-to-csv/export-to-csv-service';
import { IanCoreSingletonsModule } from 'core/services/ian-translate.module';
import { environment } from 'environments/environment';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ToastrModule } from 'ngx-toastr';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { AppRoutingModule, PrismaCanActivateRoute } from './app-routing.module';
import { IMV2_edit_service } from './item-master-v2/edit/IMV2_edit.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AppIsInSaveService } from './services/appIsInSave.service';
import { AuthorizationGuard } from './services/authorization.guard';
import { CanDeactivateGuard } from './services/guards/can-deactivate-form.guard/can-deactivate-form.guard';
import { HttpCancelInterceptor } from './services/http-cancel.interceptor';
import { MessagingService } from './services/messaging.service';
import { CustomStorage } from './services/oidc-security-storage';
import { StatusConecctionService } from './services/status_conecction.service';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { SharedModule } from './shared.module';
import { AppState } from './store/store.state';
import { PrismaDynamicEnv } from 'core/services/ian-core-singleton.service';
import { Subject } from 'rxjs';
import { PermissionsManagerService } from './services/permissions-manager.service';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

// Se dinamizó,viene del enviroment
// let oidc_configuration = 'assets/auth.clientConfiguration.json';
const oidc_configuration = _getEnv('oidc_configuration');

// if your config is on server side
// const oidc_configuration = ${window.location.origin}/api/ClientAppSettings

export function loadConfig(oidcConfigService: OidcConfigService, prismaDynamicEnv: PrismaDynamicEnv) {
  const prismaDynamicEnvIsLoadedPromise = () => {
    return new Promise(resolve => {
      const subscription: Subject<boolean> = prismaDynamicEnv.isLoaded;
      subscription.subscribe(async v => {
        if (v === true) {
          resolve(true);

          subscription.next();
          subscription.complete();
        }
      });
    });
  };

  //Da por finalizada la carga del config cuando oidcConfigService y prismaDynamicEnvIsLoadedPromise están listos
  return () =>
    new Promise(resolve => {
      Promise.all([oidcConfigService.load(oidc_configuration), prismaDynamicEnvIsLoadedPromise()]).then(valArray => {
        if (true) _log('[APP_INITIALIZER]', { valArray, oidc_configuration });
        if (valArray.every(el => !!el)) resolve(true);
      });
    });
}

export function loadConfigOld(oidcConfigService: OidcConfigService) {
  if (true) return () => oidcConfigService.load(oidc_configuration);
}

export let AppInjector: Injector;

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent, UnauthorizedComponent, ForbiddenComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AuthModule.forRoot({ storage: CustomStorage }),
    // AuthModule.forRoot(),

    TranslateModule.forRoot(),

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    // FuseProgressBarModule,
    // FuseSharedModule,
    // FuseSidebarModule,
    // FuseThemeOptionsModule,

    // App modules
    LayoutModule,
    AppRoutingModule,
    SharedModule,

    !environment.production
      ? [NgxsModule.forRoot([AppState], { developmentMode: true }), NgxsReduxDevtoolsPluginModule.forRoot()]
      : [NgxsModule.forRoot([AppState])],

    NgxsFormPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsResetPluginModule.forRoot(),
    IanCoreSingletonsModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    // Firebase modules
    // AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    ToastrModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: false && environment.production }),
  ],
  providers: [
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      deps: [OidcConfigService, PrismaDynamicEnv],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCancelInterceptor,
      multi: true,
    },
    AuthorizationGuard,
    DataAnauthorizedService,
    CanDeactivateGuard,
    { provide: MatDialogRef, useValue: {} },
    ExportToCsvFileServices,
    PermissionsManagerService,
    DisplayedColumnsService,
    MessagingService,
    StatusConecctionService,
    AppIsInSaveService,
    AsyncPipe,
    PrismaCanActivateRoute,
    DatePipe,
    IMV2_edit_service,
    { provide: MAT_DIALOG_DATA, useValue: {} },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private oidcSecurityService: OidcSecurityService, private oidcConfigService: OidcConfigService, private injector: Injector) {
    AppInjector = this.injector;

    this.oidcConfigService.onConfigurationLoaded.subscribe((configResult: any) => {
      if (!configResult || !configResult.customConfig || !this.oidcSecurityService.setupModule) return;
      const config = configResult.customConfig;
      this.oidcSecurityService.setupModule(config, configResult.authWellknownEndpoints);
    });
  }
}
