import { environment } from 'environments/environment';
import { _cloneDeep, _get, _ms, _toNumber } from './aux_helper_functions';
import { getEnabledBlockVal } from './disabled_blocks';
import packageJson from '../../../package.json';
import { _setUseUXV2 } from 'app/layout/components/global-settings-panel/global-settings-panel.component';
import { simpleKaysh } from '@karacas/kaysh';
import { _getStaticBrandCustomization } from 'core/services/ian-core-singleton.service';
const $environment = _cloneDeep(environment);
$environment._cloned = true;

const _global = window as any;

const _keyVer = environment.localStorageKeyVerPrefix || '_v3_';
const _keyVerbose = _keyVer + '__force_verbose';

const packageJsonVersion = packageJson?.version || '3.1.1';

if (_global) {
  const verboseSaved = !!localStorage.getItem(_keyVerbose);
  if (verboseSaved) {
    _global.__force_verbose = true;
  }
}

//Setup Kaysh (cache)
simpleKaysh.__setLocalStorageGlobals(
  _keyVer + 'KAYSH-V1',
  environment.maxTimeuseLocalStorageToCacheEndPoints > 0 ? environment.maxTimeuseLocalStorageToCacheEndPoints : _ms('6h'),
  true,
  environment.useLocalStorageToCacheEndPoints === true
);

if (_global) {
  _global._kaysh_resetAllCaches = () => {
    simpleKaysh.resetAllCaches();
  };
}

const _clearAllLocalStorageData = () => {
  _log('\n\n[clearAllLocalStorageData]\n\n');
  localStorage.removeItem(_keyVer + '__token');
  localStorage.removeItem(_keyVer + 'userData_permission');
  localStorage.removeItem(_keyVer + 'isAuthorized');
  localStorage.removeItem(_keyVer + 'userData');
  if (false) localStorage.removeItem(_keyVer + 'colorTheme');
  simpleKaysh.resetAllCaches();

  _deleteAllCookies();
};

function _deleteAllCookies() {
  try {
    _deleteAllCookiesNew();
    return;
  } catch (e) {}

  const cookies = document.cookie?.split?.(';');
  if (cookies == null) return;

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
}

async function _deleteAllCookiesNew() {
  let cookies = document.cookie;
  if (cookies == null) return;

  if (globalThis?.cookieStore) {
    let _cookies = await globalThis.cookieStore.getAll();

    _cookies.forEach(cookie => {
      globalThis.cookieStore.delete(cookie.name);
    });
  }

  document.cookie.replace(/(?<=^|;).+?(?=\=|;|$)/g, name =>
    String(location.hostname)
      .split(/\.(?=[^\.]+\.)/)
      .reduceRight((acc, val, i, arr) => (i ? (arr[i] = '.' + val + acc) : ((arr[i] = ''), arr)), '')
      // @ts-ignore
      .map(domain => (document.cookie = `${name}=;max-age=0;path=/;domain=${domain}`))
  );
}

/**
 * trae una ruta del environment
 *
 * @param {string} path
 * @returns
 */
const _getEnv = (path: string, _default?: any) => {
  if (!path) {
    return null;
  }

  return _get($environment, path, _default);
};

/**
 * id !prod
 *
 * @param {string} path
 * @returns
 */
const _isDev = (): boolean => environment.production !== true || window.location.href.indexOf('://localhost:') !== -1;
_global._isDev = _isDev;

const _isFeDev = (): boolean => environment.production !== true && environment.isFeDev === true;
_global._isFeDev = _isFeDev;

const _isLab = (): boolean =>
  window && window.location && window.location.href && window.location.href.indexOf('lab.prismaretail.dev') !== -1;
_global._isLab = _isLab;

const _isStaging = (): boolean =>
  window && window.location && window.location.href && window.location.href.indexOf('staging.prismaretail.dev') !== -1;
_global._isStaging = _isStaging;

const _isPrismart = (): boolean =>
  window && window.location && window.location.href && window.location.href.indexOf('prismart.prismaretail.dev') !== -1;
_global._isPrismart = _isPrismart;

_global._setUseUXV2 = _setUseUXV2;

const _isDummyDataDemoSupplyTMP = () => {
  return _useDummyData() || (getEnabledBlockVal && getEnabledBlockVal('DEMO-SUPPLY-DUMMY-DATA'));
};

let _lastGetOS = null;
const _getOS = () => {
  if (_lastGetOS) return _lastGetOS;

  if (!(window && window.navigator && window.navigator.userAgent && window.navigator.platform)) return null;

  let userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'mac';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'ios';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'windows';
  } else if (/Android/.test(userAgent)) {
    os = 'android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'linux';
  }

  _lastGetOS = os;

  return os;
};

/**
 * id prod
 *
 * @param {string} path
 * @returns
 */
const _isProd = (): boolean => !_isDev();

/**
 * dev logs // TODO: Poder prender los verbose en producción id:M2YAeqVpPe
 */
const _isVerbose = (): boolean => _global.__force_verbose || (_isDev() && environment.isVerbose === true);

const _isVerboseTaps = (): boolean => _global.__force_verboseTaps || (_isDev() && environment.isVerboseTaps === true);

const _isVerboseDeSubscribe = (): boolean => _isDev() && environment.isVerboseDeSubscribe === true;

//DES-391 //DES-1695
const _is_assorment_config_readonly = (): boolean => {
  let rv = environment.assorment?.is_config_in_readonly === true || getEnabledBlockVal('ASSORMENT-IS-CONFIG-READ-ONLY') === true;

  if ((_isDev() || _isLab()) && rv === true) {
    // A pedido de Leo, Lab no tiene readOnly
    _log(['_is_assorment_config_readonly'], rv);
    rv = false;
  }

  _log(
    ['_is_assorment_config_readonly'],
    rv,
    'is_config_in_readonly:',
    environment.assorment?.is_config_in_readonly === true,
    'ASSORMENT-IS-CONFIG-READ-ONLY:',
    getEnabledBlockVal('ASSORMENT-IS-CONFIG-READ-ONLY') === true
  );

  return rv;
};

/**
 * use DummyData
 */
const _useDummyData = (): boolean => {
  let isDev = _isDev();

  if (environment.useDummyData === true && isDev) {
    // Por dev y useDummyData env
    return true;
  }

  if (getEnabledBlockVal && getEnabledBlockVal('DEMO-USE-DUMMY-DATA') === true) {
    // Por Flag / des-177
    if (!isDev) console.warn('\n\n[DEMO-USE-DUMMY-DATA]');
    return true;
  }

  return false;
};

/**
 * custom consoles
 */
const _log = true && _isVerbose() ? console.log : (...args) => {};
const _logTap = true && _isVerboseTaps() ? console.log : (...args) => {};
const _info = true && _isVerbose() ? console.info : (...args) => {};
const _debug = true && _isVerbose() ? console.debug : (...args) => {};
const _error = true && _isVerbose() ? console.error : (...args) => {};
const _warn = true && _isVerbose() ? console.warn : (...args) => {};
const _warnProduction = args => console.warn('🔔 ' + args[0], ...args);

const _logTmp =
  true && _isFeDev()
    ? ((...$args) => {
        let args = Array.prototype.slice.call($args);

        args.unshift('\n');
        args.unshift('background: #d035a3; color: #fff; margin-left:4px; margin-bottom:4px; padding:2px 4px; border-radius:5px');
        args.unshift('%cTMPLOG');
        args.unshift(console);

        return Function.prototype.bind.apply(console.log, args);
      })()
    : () => {};

const _trace =
  true && _isDev()
    ? ((...$args) => {
        let args = Array.prototype.slice.call($args);

        args.unshift('\n');
        args.unshift('background: #a5d435; color: #fff; margin-left:4px; margin-bottom:4px; padding:2px 4px; border-radius:5px');
        args.unshift('%cTRACE');
        args.unshift(console);

        return Function.prototype.bind.apply(console.trace, args);
      })()
    : () => {};

/**
 * Imprime un objeto formateado
 *
 * @param {{}} obj
 * @param {(string | undefined)} [label=undefined]
 * @param {string} [_color='#bada55']
 * @returns
 *
 *_logObj({ a: 1, b: 2 });
_l ogObj({ a: 1, b: 2 }, 'test', '#f00');
 */
const _logObj = (obj: {}, label: string = null, _color = '#bada55'): void => {
  if (!_isVerbose()) {
    return;
  }
  if (!(obj !== null && typeof obj === 'object')) {
    console.log(obj, 'is not an object');
  } else {
    console.log('%c\n' + (label !== null ? '[' + label + ']\n' : '') + JSON.stringify(obj, null, 2), 'color:' + _color, '\n');
  }
};

export {
  _getEnv,
  _isDev,
  _isFeDev,
  _getOS,
  _isLab,
  _isStaging,
  _isPrismart,
  _isProd,
  _isVerbose,
  _isVerboseTaps,
  _isVerboseDeSubscribe,
  _useDummyData,
  _log,
  _logTap,
  _logTmp,
  _logTmp as __log,
  _trace,
  _info,
  _debug,
  _logObj,
  _error,
  _warn,
  _warnProduction,
  _is_assorment_config_readonly,
  _isDummyDataDemoSupplyTMP,
  _clearAllLocalStorageData,
};

// [PRINTS]
const printInfo = () => {
  _log(
    `%c
._____.____..__.._____.________._____.
|  _  |   _||  ||__ --|        |  _  |
|   __|__|  |__||_____|__|__|__|_._._|
|__|                           v${packageJsonVersion}
`,
    'color:#9980ff'
  );

  _logObj({
    _isDev: _isDev(),
    _isFeDev: _isFeDev(),
    _isLab: _isLab(),
    _isStaging: _isStaging(),
    _isPrismart: _isPrismart(),
    _isProd: _isProd(),
    _getOS: _getOS(),
    _isVerbose: _isVerbose(),
    _useDummyData: _useDummyData(),
    _isVerboseTaps: _isVerboseTaps(),
    _isVerboseDeSubscribe: _isVerboseDeSubscribe(),
    _versionFE: $environment._versionFE,
    _versionFEPack: packageJsonVersion,
  });
  _log({
    environment: $environment,
  });
  _log('\n\n\n');
};

if (_isDev() || _isLab()) {
  printInfo();
}

if (_global) {
  _global._info = _global._info ? _global._info : {};
  _global._info._isDev = _isDev();
  _global._info._isFeDev = _isFeDev();
  _global._info._isLab = _isLab();
  _global._info._isStaging = _isStaging();
  _global._info._isPrismart = _isPrismart();
  _global._info._getOS = _getOS();
  _global._info._environment = _global._info._environment || $environment;
  _global._info._versionFE = $environment._versionFE;
  _global._info._versionFEPack = packageJsonVersion;
  _global._info._FBtoken = _global._info._FBtoken || null;
  _global.__force_verbose = false;
  _global.__force_verboseTaps = false;
  _global._is_assorment_config_readonly = _is_assorment_config_readonly;

  if (_global.__force_verbose) {
    if (!_isDev()) {
      printInfo();
    }
  }

  _global.__enableVerbose = (val = true) => {
    _global.__force_verbose = !!val;

    if (_global.__force_verbose) {
      localStorage.setItem(_keyVerbose, 'true');
      printInfo();
    } else {
      localStorage.removeItem(_keyVerbose);
    }
  };

  _global.__enableVerboseTaps = (val = true) => {
    _global.__force_verboseTaps = val;
  };
}

//Title
export const _setDevPageTitle = () => {
  const setTtile = envStr => {
    let str = `PRISMA ${envStr} ${packageJsonVersion}`;
    if (document) document.title = str;
  };

  if (_isFeDev()) return setTtile('FEDEV');

  if (_isDev()) return setTtile('DEV');

  if (_isLab()) return setTtile('LAB');

  if (_isStaging()) return setTtile('STG');

  let code = _get(_getStaticBrandCustomization(), 'code', '');
  return setTtile(code);
};

_setDevPageTitle();
