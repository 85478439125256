import { Component, ViewEncapsulation } from '@angular/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

//CORE-40 > Chateá con tus datos

@Component({
  selector: 'quick-panel-data-ai',
  template: `
    <div fxLayout="column" fxFlex class="quick-panel-data-ai-wrapp">
      <mat-list class="date" cdkFocusRegionStart>
        <mat-icon (click)="closeQuickPanel()" class="closeQuickPanel">close</mat-icon>
        <h3 matSubheader class="notification-title">
          {{ 'Conversá con tus datos' }}
        </h3>
      </mat-list>

      <div class="chat-container">
        <div class="messages" fxFlex>
          <div
            *ngFor="let message of messages"
            [ngClass]="{ 'from-user': message.from === 'user', 'from-ai': message.from === 'ai' }"
            class="message"
          >
            <div *ngIf="message.text.chart && message.textComplete">
              <canvas
                baseChart
                [datasets]="message.text.chart.datasets"
                [labels]="message.text.chart.labels"
                [options]="message.text.chart.options"
                [legend]="message.text.chart.legend"
                [chartType]="message.text.chart.type"
              >
              </canvas>
            </div>
            <div>
              {{ message.text.text || message.text }}
            </div>
          </div>
        </div>
      </div>

      <div class="input-container">
        <input matInput [(ngModel)]="userInput" placeholder="Escribe un mensaje..." />
        <button mat-icon-button (click)="sendMessage()">
          <mat-icon>send</mat-icon>
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      .quick-panel-data-ai-wrapp {
        font-size: 16px;
      }

      .closeQuickPanel {
        float: right;
        cursor: pointer;
        margin-top: 16px;
        margin-right: 12px;
        font-size: 20px;
        opacity: 0.4;
        &:hover {
          opacity: 1;
        }
      }

      .notification-title {
        font-size: 20px !important;
        color: var(--main-greenColor) !important;
      }

      .assistant-intro {
        padding: 5px 20px;
      }

      .chat-container {
        display: block !important;
        height: calc(100% - 150px); /* Adjust height to leave space for the input */
        padding: 10px;
      }

      .messages {
        flex: 1;
        overflow-y: auto;
        padding: 10px;
        border: 1.5px solid #c9c9c9;
        border-radius: 10px;
        margin-bottom: 10px;
        background-color: #f9f9f9;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        min-height: calc(100vh - 24px);
      }

      .message {
        padding: 8px;
        margin: 5px 0;
        border-radius: 10px;
        max-width: 80%;
        word-wrap: break-word;
      }

      .from-user {
        background-color: #f0f0f0;
        align-self: flex-end;
        margin-left: auto;
      }

      .from-ai {
        background-color: #e1eeff !important;
        align-self: flex-start;
        font-weight: 600;
        margin-right: auto;
      }

      .input-container {
        display: flex;
        align-items: center;
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 10px;
        background: white;
        border-top: 1px solid #ccc;
      }

      .input-container input {
        flex: 1;
        margin-right: 10px;
        font-size: 16px;
        background-color: #d0def0 !important;
        border-radius: 6px !important;
        padding: 16px;
      }

      .input-container input::placeholder {
        color: var(--main-greenColor) !important;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class QuickPanelComponentAi {
  messages = [{ from: 'ai', text: 'Estoy bien, gracias. ¿En qué puedo ayudarte hoy?' }];
  userInput = '';

  constructor(private _fuseSidebarService: FuseSidebarService) {}

  closeQuickPanel(): void {
    if (true) {
      setTimeout(() => {
        this._fuseSidebarService.getSidebar('quickPanelAi') && this._fuseSidebarService.getSidebar('quickPanelAi').close();
      });
    }
  }

  sendMessage(): void {
    if (this.userInput.trim()) {
      this.messages.push({ from: 'user', text: this.userInput });

      this.userInput = '';

      setTimeout(() => {
        this.mockResponse();
      }, 500);
    }
  }

  mockResponse(): void {
    const responses = [
      {
        text: 'Las ventas de hoy en la categoría de bebidas son de $5,000. Esto representa un incremento del 8% en comparación con el día anterior. Los productos más vendidos incluyen Coca-Cola y Red Bull.',
        chart: {
          datasets: [
            {
              data: [65, 59, 80, 81, 56, 55, 40],
              label: 'Ventas de Bebidas',
            },
          ],
          labels: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
          options: {
            responsive: true,
            maintainAspectRatio: false,
          },
          legend: true,
          type: 'line',
        },
      },
      {
        text: "El producto más vendido en la categoría de snacks es Lay's, con un total de 300 unidades vendidas. Esto ha generado ingresos de $900. El segundo producto más vendido es Doritos.",
      },
      {
        text: 'Actualmente tienes 20 unidades en stock del producto XYZ en la categoría de lácteos. Este producto ha tenido una demanda constante y se espera que se agote en los próximos 5 días si las ventas continúan al mismo ritmo.',
      },
      {
        text: 'El margen de beneficio de este mes en la categoría de productos frescos es del 25%. Esto es un aumento del 3% en comparación con el mes pasado. Los productos con mayor margen de beneficio incluyen frutas y verduras.',
      },
      {
        text: 'Las ventas en la categoría de productos de limpieza han aumentado un 12% respecto al mes pasado. Este incremento se debe principalmente a la campaña de marketing reciente y a las promociones especiales en detergentes y desinfectantes.',
      },
    ];

    const randomResponse = responses[Math.floor(Math.random() * responses.length)];
    this.simulateTyping(randomResponse);
  }

  simulateTyping(response: any): void {
    let currentText = '';
    const minTypingSpeed = 3; // Minimum typing speed
    const maxTypingSpeed = 40; // Maximum typing speed

    const interval = setInterval(() => {
      if (currentText.length < response.text.length) {
        currentText += response.text.charAt(currentText.length);
        this.updateLastMessage({ ...response, text: currentText, textComplete: false });
      } else {
        clearInterval(interval);
        this.updateLastMessage({ ...response, textComplete: true });
      }
    }, this.getRandomTypingSpeed(minTypingSpeed, maxTypingSpeed));
  }

  getRandomTypingSpeed(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  updateLastMessageNew(response: any): void {
    if (this.messages.length > 0 && this.messages[this.messages.length - 1].from === 'ai') {
      this.messages[this.messages.length - 1] = response;
    } else {
      this.messages.push(response);
    }
  }

  updateLastMessage(text: string): void {
    if (this.messages.length > 0 && this.messages[this.messages.length - 1].from === 'ai') {
      this.messages[this.messages.length - 1].text = text;
    } else {
      this.messages.push({ from: 'ai', text });
    }
  }
}
